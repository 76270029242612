:root {
  --primary-color: rgba(0, 99, 141, 1);
  --secondary-color: #f2f2f2;
}

@media (max-width: 500px) {
  .list a {
    height: auto !important;
    padding: 10px !important;
  }
}

.list {
  list-style: none;
  padding: 0;
}

.list a {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 50px;
  width: 100%;
  height: 40px;
  background-color: var(--secondary-color);
  opacity: 0.8;
  padding: 0 10px;
  color: black;
  font-size: 16px;
  font-weight: 600;
  margin: 5px;
  text-decoration: none;
  border-radius: 5px;
  border: 2px solid var(--primary-color);
  transition: all 0.2s ease-in-out;
}

.list a:hover {
  background-color: var(--primary-color);
  opacity: 1;
  color: white;
  transform: translateY(-1px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
