@media (min-width: 200px) {
  .menu {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.menu {
  padding-top: 20px;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
}

.menuItem {
  text-decoration: none;
  color: black;
  padding-right: 35px;
  text-transform: uppercase;
  font-size: 1.2rem;
  display: inline-block;
}

.menuItem:hover {
  color: rgb(59, 152, 228);
}

.menuButton {
  background: none;
  border: none;
  padding: 0px;
  text-decoration: none;
  color: black;
  text-transform: uppercase;
  font-size: 1.2rem;
  display: inline-block;
}

.menuButton:hover {
  color: rgb(59, 152, 228);
}
