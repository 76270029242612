.body {
  display: flex;
}

.loginOption {
  background-color: rgba(255, 255, 255, 1);
  height: 100px;
  border-radius: 5px;
  width: 460px;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
}

@media (min-width: 452px) {
  .loginOption {
    box-shadow: 0px 2px 10px 0px #000000;
  }
}

@media (max-width: 500px) {
  .loginOption {
    width: 100px !important;
  }

  .logonOptionImg {
    height: 45px !important;
  }

  .logonOptionText {
    width: 350px !important;
    height: 150px !important;
    font-size: 18px !important;
    line-height: 28px !important;
  }

  .btn {
    width: 350px !important;
  }

  .seperatorLine {
    width: 150px !important;
  }
}

.logonOptionImg {
  align-self: center;
  margin-top: 45px;
  height: 52px;
}

.logonOptionText {
  margin-top: 60px;
  margin-bottom: 40px;
  align-self: center;
  width: 360px;
  height: 145px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #292929;
}

.btn {
  align-self: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  border: none;
  height: 55px;
  width: 385px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 0.91);
  transition: all 0.2s ease;
}

.btnSocial {
  background: #006892;
  opacity: 0.9;
}

.btnLater {
  background: #387c2b;
  opacity: 0.9;
}

.btn:hover {
  opacity: 1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
}

.seperator {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
}

.seperatorLine {
  border: 1px solid rgba(63, 61, 61, 0.56);
  width: 190px;
  height: 0px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.seperatorText {
  margin: unset;
  padding: unset;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: #606060;
}

.seperatorLeft {
  margin-right: 10px;
}

.seperatorRight {
  margin-left: 10px;
}
