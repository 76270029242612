@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1000px !important;
  }
}

@media (max-width: 450px) {
  .bgImage {
    display: none !important;
  }
}

.PageContainer {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.568);
  z-index: 2;
}

footer {
  flex-shrink: 0;
  z-index: 2;
}

main {
  flex: 1 0 auto;
  z-index: 1;
}

.bgImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  position: fixed;
  left: 0;
  overflow: hidden;
  top: 0;
  vertical-align: middle;
  border-style: none;
}
