@media (min-width: 450px) {
  .footerArea {
    background-color: black;
    opacity: 0.8;
  }
}

@media (max-width: 500px) {
  .footerArea {
    height: auto !important;
  }
}

.footerArea {
  width: 100%;
  height: auto;
  background-color: #000000;
  background-size: cover;
  text-align: center;
  font-size: 15px;
}
