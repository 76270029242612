.tileImage {
  width: 100%;
  opacity: 0.9;
  transition: ease-in-out 0.2s;
  border-radius: 5px;
}

.tileImage:hover {
  opacity: 1;
  border-radius: 5px;
  border: 3px solid #e6e6e6;
}

.tileImage:hover + .overlay {
  opacity: 1;
  margin-top: 3px;
  margin-right: 3px;
  transition: ease-in 0.2s;
}

.blankTile {
  background-color: whitesmoke;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.overlayContainer {
  position: relative;
  cursor: pointer;
}

.overlayIcon {
  color: whitesmoke;
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-right: 4px;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  text-align: end;
}

@media (min-width: 1030px) {
  .overlay {
    opacity: 1;
  }
}

.overlay:hover {
  opacity: 1;
}
