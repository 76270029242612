@media (max-width: 500px) {
  .titleText {
    color: #000000 !important; 
    text-shadow: none !important;
  }
}

.titleText {
  display: block;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 1px 1px 2px #000000;
  font-size: 28px;
  margin-bottom: 20px;
}
