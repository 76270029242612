@media (max-width: 991px) {
  .newsListContainer {
    grid-template-columns: repeat(auto-fit, minmax(99%, 1fr)) !important;
  }
}

@media (min-width: 450px) {
  .chevronRight {
    background-color: #006892 !important;
    border: 1px solid #006892 !important;
  }
}

.newsListContainer {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  opacity: 0.9;
}

.chevronRight {
  vertical-align: middle;
  width: 60px;
  height: 60px;
  background-color: #00638d;
  padding: 1rem;
  transition: 0.3s ease;
  border-radius: 5px;
}

.newsItemContainer:hover .chevronRight {
  transform: translateX(5px);
}

.newsItemContainer:hover .newsItemText {
  transform: translateX(5px);
}

.newsItemContainer {
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.newsItemContainer:hover {
  opacity: 1;
}

.newsItemText {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
  transition: 0.3s ease;
}

.newsItemText p {
  margin: 0;
  padding-left: 10px;
  color: black;
  font-size: 16px;
  font-weight: 600;
}

.newsItemLink {
  text-decoration: none;
}
