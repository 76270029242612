@media (max-width: 500px) {
  .titleText {
    color: #000000 !important; 
    text-shadow: none !important;
  }
}

.titleText {
  display: inline;
  font-weight: bold;
  color: #ffffff;
  font-size: 28px;
  text-shadow: 1px 1px 2px #000000;
  font-size: 28px;
}

.linkStyle {
  display: flex;
  float: right;
  height: 40px;
  width: 70px;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid white;
  background-color: rgba(0, 99, 141, 0.8);
  color: white;
  font-weight: 600;
  border-radius: 5px;
}

.linkStyle:hover {
  background-color: rgba(0, 99, 141, 1);
  transition: ease-in-out 0.3s;
  color: white;
}
